import React, { createContext, useState, ReactNode } from "react";
import { Dayjs } from "dayjs";

interface GlobalState {
  availabilityDate: Dayjs | null;
  availabilityTime: string;
  serviceName: string;
  providerId: number;
  serviceProviderId: number;
  serviceProviderName: string;
  serviceAmount: string;
  extraService: string;
  additionalRequest: string;
  carDriverway: string;
  snowArea: string;
  numberOfToilet: string;
  cleaningArea: string;
  numberOfBedroom: string;
  numberOfBathroom: string;
  serviceLocation: string;
}

interface GlobalStateContextProps {
  globalState: GlobalState;
  setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>;
}

export const GlobalStateContext = createContext<
  GlobalStateContextProps | undefined
>(undefined);

interface GlobalStateProviderProps {
  children: ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({
  children,
}) => {
  const [globalState, setGlobalState] = useState<GlobalState>({
    availabilityDate: null,
    availabilityTime: "",
    serviceName: "",
    providerId: 0,
    serviceProviderId: 0,
    serviceProviderName: "",
    serviceAmount: "",
    extraService: "",
    additionalRequest: "",
    carDriverway: "",
    numberOfBedroom: "",
    numberOfBathroom: "",
    snowArea: "",
    numberOfToilet: "",
    cleaningArea: "",
    serviceLocation:"",
  });

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
